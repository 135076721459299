/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        /****************
        **  SVG swap out with PNG on incompatible browsers
        *****************/
        if (!Modernizr.svg) {
          $('img[src*="svg"]').attr('src', function() {
            return $(this).attr('src').replace('.svg', '.png');
          });
        }

        /****************
        **  Initiate Social share buttons
        *****************/
        (function(){
          socializer( '.socializer' );
        }());


        /****************
        **  Table of Contents scroll awareness (active states added to nav buttons)
        *****************/
        $(document).on("scroll", onScrollTOC);


        /****************
        **  MEGA MENU overlays and css tweaks
        *****************/

        $("ul#mega-menu-primary_navigation").on("mmm:showMobileMenu", function() {
          $('body').addClass('modal-open');
      	});
        $("ul#mega-menu-primary_navigation").on("mmm:hideMobileMenu", function() {
          $('body').removeClass('modal-open');
      	});
        $('li.mega-menu-item').on('open_panel', function() {
          $('body').addClass('modal-open');
        });
        $('li.mega-menu-item').on('close_panel', function() {
          $('body').delay(3000).removeClass('modal-open');
        });
        $('li.mega-split-tone').parent('ul.mega-sub-menu').addClass('mega-split-tone-wrap');



        /****************
        **  Carousels
        *****************/
        $('.testimonials-carousel').each(function() {
          $(this).slick({
            rows: 0,
            dots: true,
            infinite: true,
            speed: 400,
            fade: true,
            cssEase: 'linear',
            autoplay: true,
            autoplaySpeed: 8000,
            prevArrow: $(this).find(".prev-testimonial"),
            nextArrow: $(this).find(".next-testimonial"),
//             appendDots:$(this).find('.dots')
          });
        });
        $(".testimonials-carousel div.slick-slide").matchHeight();


        /****************
        **  Gallery lightbox
        *****************/
        $('.gallery-grid').slickLightbox({
          caption: 'caption',
          useHistoryApi: 'true'
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // PROGRAM LISTING page
    'programs': {
      init: function() {
        window.onload = Toolbox.programListFilter;
        $('#portfolioFilter select,#portfolioFilter input').on('change', Toolbox.programListFilter);



        $('#portfolioFilter a.clear-all').click(function(e){
          e.preventDefault();
          $('#portfolioFilter input#search-term').val('');
          $('#portfolioFilter select').prop("selectedIndex", 0);
          return false;
        }).on('click', Toolbox.programListFilter);

        $('.filtered-selection li a').click(function(e){
          e.preventDefault();
          var selectName = $(this).parent('li').attr('id');
          selectName = selectName.replace("filtered-", "");
          $('#portfolioFilter select#'+selectName).prop("selectedIndex", 0);
          $('#portfolioFilter input#'+selectName).val('');
          return false;
        }).on('click', Toolbox.programListFilter);


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // SINGLE PROGRAM page
    'single_program': {
      init: function() {

        /****************
        **  Expand Course List accordion from anchor link
        *****************/
        var courseName = $(window.location.hash).attr('id');
        if (courseName) {
          // get height of sticky Program nav
          var heightTop = $('.wp-block-aioseo-table-of-contents').height();
          // compensate for padding and title
          heightTop = heightTop + 80;

          $('#'+courseName).collapse('show');
          $('#'+courseName).on('shown.bs.collapse', function () {
            $('html, body').animate({
              scrollTop: $('#'+courseName).offset().top-heightTop
            }, 'fast' );
          });
        }


        /****************
        **  Add styles when Course Registration link is expanded
        *****************/
        $('#courseRegistrations').on('show.bs.collapse', function () {
          $(this).parent('.btn-applyregister.course-links').addClass('expanded');
        });
        $('#courseRegistrations').on('hidden.bs.collapse', function () {
          $(this).parent('.btn-applyregister.course-links').removeClass('expanded');
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // BLOG
    'blog': {
      init: function() {

        /****************
        **  Category Jump Menu
        *****************/
        $('select[name="category"]').on('change', function () {
          var url = $(this).val();
          if (url) { window.location = url; }
          return false;
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // single article
    'single_post': {
      init: function() {

        /****************
        **  Add active state to main menu
        *****************/
        $('#mega-menu-primary_navigation #mega-menu-item-859').addClass('mega-current-page-ancestor');

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // ARCHIVE
    'archive': {
      init: function() {

        /****************
        **  Category Jump Menu
        *****************/
        $('select[name="category"]').on('change', function () {
          var url = $(this).val();
          if (url) { window.location = url; }
          return false;
        });


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // COURSE LISTING
    'page_template_template_course_list': {
      init: function() {

        $('.course-listings form input[type="radio"]').click(function() {
          $('.course-listings .actionBar button[type="submit"]').removeAttr('disabled');
        });


        $('body:not(.page-id-15132) .course-listings.pre-college-apply ul.enginece_section_list').each(function () {
            $('[data-enginece-marketing-programs*=HIGHLIGHTED]', this).addClass('highlighted').prependTo(this);
//             $(':not([data-enginece-marketing-programs*=HIGHLIGHTED])', this).appendTo(this);
        });

        $('body:not(.page-id-15132) .course-listings.pre-college-apply ul.enginece_section_list>li.enginece_section.highlighted:first-child').before('<li class="highlighted-title bg-orange"><h3>What&rsquo;s Trending This Week</h3></li>');
        $('.course-listings.pre-college-apply ul.enginece_section_list>li.enginece_section[data-enginece-marketing-programs*=HIGHLIGHTED]:last').addClass('highlighted-final');


        $.each($(".enginece_section_list >li.enginece_section p.title .courseExpand"), function() {
          $(this).html($(this).html().replace("fas fa-caret-down", "fa-sharp fa-regular fa-angle-down").replace("fas fa-caret-up", "fa-sharp fa-regular fa-angle-up"));
        });

        /****************
        **  Remove colons from course table / change FA icons
        *****************/
/*
        $.each($(".course-information .course-details>span, .course-information .instructors"), function() {
          $(this).html($(this).html().replace(": ", "").replace(":&nbsp;", ""));
        });
*/


        /****************
        **  COURSE EXPAND VIEW
        *****************/
        $('.enginece_section_list>li p.title .courseExpand,.enginece_section_list>li p.title .courseExpandTitle').on('click',function(e){

          if ($(this).parents('li').hasClass('revealed')) {
            $(this).parents('li').removeClass('revealed');
            $(this).parents('li').find('section.course-information').slideUp(100);
          } else {
            $(this).parents('li').addClass('revealed');
            $(this).parents('li').find('section.course-information').css('opacity', 0)
              .slideDown('fast')
              .animate(
                { opacity: 1 },
                { queue: false, duration: 'fast' }
            );
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

